import { Component, OnInit } from '@angular/core';
import {ToolsService} from '../../tools.service';

@Component({
  selector: 'app-form-downloads',
  templateUrl: './form-downloads.component.html',
  styleUrls: ['./form-downloads.component.css']
})
export class FormDownloadsComponent implements OnInit {

  constructor(private toolsService: ToolsService) { }

  ngOnInit() {
    this.toolsService.setActive('forms');
  }

}
