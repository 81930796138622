export class BankForm {

  constructor() {
  }

  _id: string;
  Date: string;
  DateMillis: number;
  TransactionType: string = "";
  DepositType: string = "";
  WithdrawalType: string = "";
  Conference: string = "";
  CaseID: string = "";
  Amount: string = "";
  Notes: string = "";

}
