export class CallLog {

  constructor() {
    const d = new Date();

    this.Date = (d.getMonth() + 1) + '/' + d.getDate() + '/' + d.getFullYear();
    this.LastName = '';
    this.FirstName = '';
    this.Phone = '';
    this.Address = '';
  }

  _id: string;
  Date: string;
  DateMillis: number;
  LastName: string;
  FirstName: string;
  Phone: string;
  Address: string;
  Apt: string;
  City: string;
  State: string;
  Zip: string;
  Need: string;
  Resolution: string;
  CreatedBy: string;
  ConferenceName: string;
  ConferencePhone: string;
  HistoryCount: number;
  CreatedDate: number;

}
