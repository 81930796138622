import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, LocationStrategy, HashLocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule, Http } from '@angular/http';
import { Routes, RouterModule } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';

import { NavigationComponent } from './shared/header-navigation/navigation.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { HttpClientModule } from '@angular/common/http';

import { Approutes } from './app-routing.module';
import { AppComponent } from './app.component';
import { SpinnerComponent } from './shared/spinner.component';
import { SearchComponent } from './tools/search/search.component';

import {ToolsService} from './tools.service';
import { BoundariesComponent } from './tools/boundaries/boundaries.component';
import { ClientApplicationComponent } from './forms/client-application/client-application.component';
import { CallLogComponent } from './forms/call-log/call-log.component';
import { BankFormComponent } from './forms/bank-form/bank-form.component';
import { FoodApplicationComponent } from './forms/food-application/food-application.component';
import { FormDownloadsComponent } from './forms/form-downloads/form-downloads.component';
import { CallLogReportComponent } from './reports/call-log-report/call-log-report.component';
import { BankTransactionsReportComponent } from './reports/bank-transactions-report/bank-transactions-report.component';
import { MonthlyRollupComponent } from './reports/monthly-rollup/monthly-rollup.component';
import { ResourcesComponent } from './resources/resources.component';
import { HomeComponent } from './home/home.component';
import { CallbackComponent } from './callback/callback.component';
import { AbuseEmailComponent } from './abuse-email/abuse-email.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true,
};

@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    FullComponent,
    NavigationComponent,
    SidebarComponent,
    SearchComponent,
    BoundariesComponent,
    ClientApplicationComponent,
    CallLogComponent,
    BankFormComponent,
    FoodApplicationComponent,
    FormDownloadsComponent,
    CallLogReportComponent,
    BankTransactionsReportComponent,
    MonthlyRollupComponent,
    ResourcesComponent,
    HomeComponent,
    CallbackComponent,
    AbuseEmailComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    NgbModule.forRoot(),
    RouterModule.forRoot(Approutes, { useHash: false }),
    PerfectScrollbarModule
  ],
  providers: [
      {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
    provide: LocationStrategy,
    useClass: HashLocationStrategy
  },
    ToolsService],
  bootstrap: [AppComponent]
})
export class AppModule { }
