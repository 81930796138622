import { Component, OnInit } from '@angular/core';
import {ToolsService} from '../tools.service';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.css']
})
export class ResourcesComponent implements OnInit {

  constructor(private toolsService: ToolsService) { }

  ngOnInit() {
    this.toolsService.setActive('resources');
  }

}
