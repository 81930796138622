
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import 'rxjs/add/operator/filter';
import * as auth0 from 'auth0-js';
import { ToolsService } from './tools.service';
import { JwtHelperService } from '@auth0/angular-jwt';

const jwt = new JwtHelperService();

@Injectable()
export class AuthService {


  auth0;
  constructor(public router: Router, private toolsService: ToolsService) {
    console.log('AuthService constructor()');

    // default to prod
    // let redirectURL = 'http://toservethepoor.org/callback';
    let redirectURL = 'https://toservethepoor.org/callback';

    // check if dev
    if (window.location.hostname === 'localhost') {
      redirectURL = 'http://localhost:4200/callback';
    }

    this.auth0 = new auth0.WebAuth({
      clientID: '0HHFdMy5UeMvCvEJDvcJsflY3zOHIE1l',
      domain: 'svdp.auth0.com',
      responseType: 'token id_token',
      // audience: 'https://svdp.auth0.com/userinfo',
      audience: 'https://svdp-api',
      redirectUri: redirectURL,
      // redirectUri: 'http://localhost:4200/callback',
      // redirectUri: 'http://18.216.26.105/callback',
      scope: 'openid'
    });
  }

  public login(): void {
    console.log('AuthService login()');
    this.auth0.authorize();
  }

  public handleAuthentication(): void {
    console.log('AuthService handleAuthentication()');

    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        console.log('found authResult to process');
        window.location.hash = '';
        this.setSession(authResult);
        this.router.navigate(['/home']);
      } else if (err) {
        console.log('authResult error ' + err);
        this.router.navigate(['/home']);
        console.log(err);
      } else {
        console.log('no auth request to process & no error');
      }
    });
  }

  private setSession(authResult): void {
    console.log('AuthService setSession()');

    // Set the time that the access token will expire at
    const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);

    const decoded = jwt.decodeToken(authResult.idToken);
    const userID = decoded.sub;
    this.toolsService.getUser(userID)
      .subscribe(results => {
        console.log('set user ' + JSON.stringify(results));
        localStorage.setItem('authUser', JSON.stringify(results));
      });
  }

  public logout(): void {
    console.log('AuthService logout()');
    // Remove tokens and expiry time from localStorage
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('authUser');
    // Go back to the home route
    // this.router.navigate(['/']);
    location.reload();
  }

  public isAuthenticated(): boolean {
    console.log('AuthService isAuthenticated()');

    // Check whether the current time is past the
    // access token's expiry time
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    // console.log("id token " +localStorage.getItem('id_token'));
    // console.log("cur date " +new Date().getTime());
    // console.log("expires at " +expiresAt);
    const timeLeft = expiresAt - new Date().getTime();
    console.log('time left on token ' + timeLeft);
    return new Date().getTime() < expiresAt;
  }

}
