import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../auth.service';
import { ToolsService } from '../../tools.service';
import { Client } from '../../client';
import {Router} from '@angular/router';
import {CallLog} from '../../callLog';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
  providers: [AuthService]
})
export class SearchComponent implements OnInit {

  searchValue = '';
  clients: Client[] = [];
  callLogs: CallLog[] = [];
  abuseAlerts: any[] = [];
  processingClients = false;
  processingCalls = false;
  message = '';
  clientsMessage = '';
  callsMessage = '';
  emailMessage = undefined;

  constructor( private auth: AuthService,
               private toolsService: ToolsService,
               private router: Router,
               private modalService: NgbModal) { }

  ngOnInit() {
    console.log('init search component');
    this.toolsService.setActive('search');
  }

  search() {
    this.message = '';
    this.callsMessage = '';
    this.clientsMessage = '';
    this.processingClients = true;
    this.processingCalls = true;
    this.clients = [];

    // trim whitespace
    this.searchValue = this.searchValue.trim();

    // scrub phone search to remove '.' characters
    if (this.searchValue.length === 12) {
      this.searchValue = this.searchValue.replace(/\./g, '');
    }

    this.toolsService.getClients(this.searchValue)
      .subscribe(results => {
        this.processingClients = false;

        this.clients = results;

        if (results.length === 0) {
          this.clientsMessage = 'No Client Applications Found';
        }
      });

    this.toolsService.getCallLogs(this.searchValue)
      .subscribe(callResults => {
        this.processingCalls = false;

        this.callLogs = callResults;

        if (callResults.length === 0) {
          this.callsMessage = 'No Call Logs Found';
        }
      });

    this.toolsService.getAbuseAlerts(this.searchValue)
      .subscribe(abuseResults => {
        console.log('abuse results ' + JSON.stringify(abuseResults));

        this.abuseAlerts = abuseResults;
      });
  }

  updateClient(client) {
    this.toolsService.savedClient = client;
    this.router.navigate(['/fullApplication']);
  }

  bankInfo(client) {
    this.toolsService.savedClient = client;
    this.router.navigate(['/bankForm']);
  }

  updateCallLog(callLog) {
    console.log('update call log with address ' + callLog.Address);
    this.toolsService.savedCallLog = callLog;
    this.router.navigate(['/callLog']);
  }

  promoteCall(callLog) {
    console.log('promoteCall()');
    this.toolsService.savedCallLog = callLog;
    this.router.navigate(['/promoteCall']);
  }

  public launchModal(emailModal, messageId) {
    this.emailMessage = null;
    this.toolsService.getAbuseEmail(messageId)
      .subscribe(results => {
        this.emailMessage = {'body': results.html, 'from': results.from, 'to': results.to, 'subject': results.subject};
    });
    this.modalService.open(emailModal, { centered: true, size: 'lg', windowClass: 'modal-adaptive' }).result.then((result) => {
    }, (reason) => {
    });
  }

  getClass(isClosed) {
    let str = 'col-lg-2 col-md-3 col-sm-6 col-xs-12';
    if (isClosed) {
      str += ' card2';
    } else {
      str += ' card3';
    }
    return str;
  }

  log(message) {
    const d = new Date();
    let dateStr = '' + (d.getMonth() + 1) + '/' + d.getDate() + '/' + d.getFullYear();
    dateStr += ' ' + d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds() + ' ';
    console.log(dateStr + message);
  }
}
