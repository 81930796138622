import { Component, OnInit } from '@angular/core';
import {ToolsService} from '../tools.service';
import {Router, ActivatedRoute, Params} from '@angular/router';

@Component({
  selector: 'app-abuse-email',
  templateUrl: './abuse-email.component.html',
  styleUrls: ['./abuse-email.component.css']
})
export class AbuseEmailComponent implements OnInit {

  messageId = '';
  message = '';

  constructor(private toolsService: ToolsService,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.messageId = this.activatedRoute.snapshot.queryParams['messageId'];

    this.toolsService.getAbuseEmail(this.messageId)
      .subscribe((results) => {
        this.message = results.html;
      });
  }

}
