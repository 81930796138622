import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '', title: 'Tools', icon: '', class: 'nav-small-cap', label: '', labelClass: '', extralink: true, submenu: []
  },
  {
    path: '/search', title: 'Neighbor Search', icon: 'mdi mdi-magnify', class: '', label: '', labelClass: '', extralink: false, submenu: []
  },
  {
    path: '/callLog', title: 'New Call Log', icon: 'mdi mdi-phone', class: '', label: '', labelClass: '', extralink: false, submenu: []
  },
  {
   path: '/boundaries', title: 'Boundary Check', icon: 'mdi mdi-google-maps', class: '', label: '', labelClass: '', extralink: false, submenu: []
  },
  {
    path: '', title: 'General', icon: '', class: 'nav-small-cap', label: '', labelClass: '', extralink: true, submenu: []
  },
  {
    path: '', title: 'Interactive Forms', icon: 'mdi mdi-file-document', class: 'has-arrow', label: '', labelClass: '', extralink: false,
    submenu: [
      { path: '/fullApplication', title: 'Full Application', icon: '', class: '', label: '', labelClass: '', extralink: false, submenu: [] },
      { path: '/callLog', title: 'Call Log', icon: '', class: '', label: '', labelClass: '', extralink: false, submenu: [] },
      { path: '/bankForm', title: 'Banking Form', icon: '', class: '', label: '', labelClass: '', extralink: false, submenu: [] },
    ]
  },
  {
    path: '', title: 'Reports', icon: 'mdi mdi-playlist-minus', class: 'has-arrow', label: '', labelClass: '', extralink: false,
    submenu: [
      { path: '/callLogReport', title: 'Recent Call Logs', icon: '', class: '', label: '', labelClass: '', extralink: false, submenu: [] },
      { path: '/bankReport', title: 'Recent Bank Transactions', icon: '', class: '', label: '', labelClass: '', extralink: false, submenu: [] },
      { path: '/monthlyRollup', title: 'Monthly Rollup', icon: '', class: '', label: '', labelClass: '', extralink: false, submenu: [] },
    ]
  },
  {
    path: '/formDownloads', title: 'Form Downloads', icon: 'mdi mdi-arrow-down-bold-circle-outline', class: '', label: '', labelClass: '', extralink: false, submenu: []
  },

  {
    path: '/resources', title: 'Resources', icon: 'mdi mdi-table', class: '', label: '', labelClass: '', extralink: false,
    submenu: []
  }

];

