
export class Client {

  constructor() {
  }

  _id: string;
  LastName = '';
  FirstName = '';
  Phone = '';
  Cell = '';
  Address = '';
  Apt = '';
  City = '';
  State = '';
  Zip = '';
  Member = '';
  Date = '';
  DateMillis = 0;
  Status = '';
  Grant = '';
  Food = 0;
  Cards = 0;
  Cash = 0;
  LangenderferIn: number;
  TwinningIn: number;
  TwinningOut: number;
  CheckIssuedTo = '';
  Hours = '';
  Calls = '';
  Helpers = '';
  Visits = '';
  Miles = '';
  Referred = '';
  PastHelp = '';
  CurrentRequest = '';
  HouseholdNumber = '';
  ChildrenAges = '';
  Employer = '';
  EmployerAddress = '';
  EmployerPhone = '';
  WorkIncome = '';
  UnemploymentIncome = '';
  WorkmansComIncome = '';
  SocialSecuritIncome = '';
  ChildSupportIncome = '';
  OtherIncome = '';
  HousingExpense = '';
  AutoExpense = '';
  CreditCardsExpense = '';
  UtilitiesExpense = '';
  CellPhoneExpense = '';
  InsuranceExpense = '';
  ChildSupportExpense = '';
  RequestReason = '';
  Vincentians = '';
  VincentianHours = '';
  Mileage = '';
  Ethnicity = 'Select';
  CreatedBy = '';
  CreatedDate = 0;
  CaseClosed = false;

}
