import { Routes, RouterModule } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import {SearchComponent} from './tools/search/search.component';
import {BoundariesComponent} from './tools/boundaries/boundaries.component';
import {ClientApplicationComponent} from './forms/client-application/client-application.component';
import {CallLogComponent} from './forms/call-log/call-log.component';
import {BankFormComponent} from './forms/bank-form/bank-form.component';
import {FoodApplicationComponent} from './forms/food-application/food-application.component';
import {FormDownloadsComponent} from './forms/form-downloads/form-downloads.component';
import {CallLogReportComponent} from './reports/call-log-report/call-log-report.component';
import {BankTransactionsReportComponent} from './reports/bank-transactions-report/bank-transactions-report.component';
import {MonthlyRollupComponent} from './reports/monthly-rollup/monthly-rollup.component';
import {ResourcesComponent} from './resources/resources.component';
import {HomeComponent} from './home/home.component';
import {CallbackComponent} from "./callback/callback.component";
import {AbuseEmailComponent} from './abuse-email/abuse-email.component';

export const Approutes: Routes = [
{
    path: '',
    component: FullComponent,
    children: [
      { path: '', component: HomeComponent },
      { path: 'starter', loadChildren: './starter/starter.module#StarterModule' },
      { path: 'component', loadChildren: './component/component.module#ComponentsModule' },
      { path: 'search', component: SearchComponent },
      { path: 'fullApplication', component: ClientApplicationComponent },
      { path: 'foodApplication', component: FoodApplicationComponent },
      { path: 'callLogReport', component: CallLogReportComponent },
      { path: 'formDownloads', component: FormDownloadsComponent },
      { path: 'promoteCall', component: ClientApplicationComponent },
      { path: 'bankForm', component: BankFormComponent },
      { path: 'callLog', component: CallLogComponent },
      { path: 'boundaries', component: BoundariesComponent },
      { path: 'bankReport', component: BankTransactionsReportComponent },
      { path: 'monthlyRollup', component: MonthlyRollupComponent },
      { path: 'resources', component: ResourcesComponent },
      { path: 'home', component: HomeComponent },
      { path: 'callback', component: CallbackComponent },
      { path: 'abuse-email', component: AbuseEmailComponent },
    ]
},
{
    path: '**',
    redirectTo: '/home'
}];


