import { Component, OnInit } from '@angular/core';
import {CallLog} from '../../callLog';
import {Client} from '../../client';
import {ToolsService} from '../../tools.service';

@Component({
  selector: 'app-call-log-report',
  templateUrl: './call-log-report.component.html',
  styleUrls: ['./call-log-report.component.css']
})
export class CallLogReportComponent implements OnInit {

  processing = false;
  callLogs: CallLog[] = [];

  constructor(private toolsService: ToolsService) {
    this.getCalls();
  }

  ngOnInit() {
  }

  getCalls() {
    this.toolsService.getCallLogs('')
      .subscribe(results => {
        this.processing = false;

        // the service mapping to object array had a glitch - force instantiation of each object explicitely for access to class methods
        for (const callLog of results) {
          this.callLogs.push(callLog);
        }
      });
  }

}
