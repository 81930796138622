import { Component, OnInit } from '@angular/core';
import {ToolsService} from '../../tools.service';
import {CallLog} from '../../callLog';
import {BankForm} from '../../bankForm';
import {Client} from '../../client';

@Component({
  selector: 'app-bank-form',
  templateUrl: './bank-form.component.html',
  styleUrls: ['./bank-form.component.css']
})
export class BankFormComponent implements OnInit {

  bankForm:BankForm = new BankForm();
  showDeposit = false;
  showWithdrawal = false;
  showConferences = false;
  showAmount = false;
  page1 = true;
  page2 = false;
  firstName;
  lastName;
  messages = [];

  constructor(private toolsService: ToolsService) {
    if (this.toolsService.savedClient) {
      this.bankForm = new BankForm();
      this.bankForm.CaseID = this.toolsService.savedClient._id;
      this.firstName = this.toolsService.savedClient.FirstName;
      this.lastName = this.toolsService.savedClient.LastName;

      this.toolsService.savedClient = undefined;
    }
  }

  ngOnInit() {
  }

  setTransactionType(myType) {
    console.log('selectedType ' + myType);
    this.showAmount = false;
    this.showConferences = false;
    this.bankForm.DepositType = '';
    this.bankForm.WithdrawalType = '';

    if (myType === 'deposit') {
      this.showDeposit = true;
      this.showWithdrawal = false;
    } else if (myType === 'withdrawal') {
      this.showWithdrawal = true;
      this.showDeposit = false;
    }
  }

  setDepositType() {
    console.log('deposit type ' + this.bankForm.DepositType);
    this.bankForm.Conference = '';

    if (this.bankForm.DepositType === 'twinningDonation') {
      this.showConferences = true;
      this.showAmount = false;
    } else if (this.bankForm.DepositType === 'langenderferDonation') {
      this.showAmount = true;
      this.showConferences = false;
    } else {
      this.showAmount = true;
      this.showConferences = false;
    }
  }

  setWithdrawalType() {

    if (this.bankForm.WithdrawalType === 'twinningPayment') {
      this.showConferences = true;
      this.showAmount = false;
    } else {
      this.showAmount = true;
      this.showConferences = false;
    }
  }

  setConference() {
    console.log('set conference to ' + this.bankForm.Conference);
    this.showAmount = true;
  }

  saveBankForm() {
    if (!this.bankForm.Date) {
      this.messages.push('Date field is required.');
    }
    if (!this.bankForm.Amount) {
      this.messages.push('Amount field is required.');
    }

    // convert date string to millis for sorting
    this.bankForm.DateMillis = Date.parse(this.bankForm.Date.trim());

    if (this.messages.length === 0) {
      this.toolsService.saveBankForm(this.bankForm)
        .subscribe((results) => {
          this.bankForm._id = results._id;
        });
    }
  }

  showPage(pageNum) {
    this.page1 = false;
    this.page2 = false;

    switch (pageNum) {
      case 1:
        this.page1 = true;
        break;
      case 2:
        this.page2 = true;
        break;
    }
  }

  newForm() {
    this.bankForm = new BankForm();
    this.showPage(1);

    this.showDeposit = false;
    this.showWithdrawal = false;
    this.showConferences = false;
    this.showAmount = false;
  }

}
