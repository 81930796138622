import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Client } from './client';
import {User} from './user';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/timeout';
import 'rxjs/add/operator/catch';
import {CallLog} from './callLog';
import {MonthlyRollup} from './monthlyRollup';
import {BankForm} from './bankForm';
import {Address} from './address';

@Injectable()
export class ToolsService {

  activeComponent = '';
  baseUrl;

  savedClient: Client;
  savedCallLog: CallLog;

  constructor(private http: HttpClient) {

    // default to prod
    this.baseUrl = 'https://toservethepoor.org';

    // check if dev
    if (window.location.hostname === 'localhost') {
      this.baseUrl = 'http://localhost:9000';
    }
  }

  getActive(name) {
    if (this.activeComponent === name) {
      return 'active';
    } else {
      return '';
    }
  }

  setActive(name) {
    this.activeComponent = name;
  }

  getUserMessage() {
    const obj = JSON.parse(localStorage.getItem('authUser'));
    if (obj) {
      return obj.firstName + ' ' + obj.lastName;
    } else {
      return '';
    }
  }

  getClients(search): Observable<Client[]> {
    const url = this.baseUrl + '/clients?filter=' + search;

    const options = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': localStorage.getItem('access_token')
      })
    };

    return this.http.get<Client[]>(url, options );
  }

  getBoundaries(): Observable<any> {
    const url = '../assets/kml/denver-boundaries.kml';

    return this.http.get(url, { responseType: 'text' });
  }

  scrubAddress(address, city, state, zip): Observable<any> {
    // const url = 'http://production.shippingapis.com/ShippingAPITest.dll?API=Verify' +
    const url = 'https://secure.shippingapis.com/ShippingAPI.dll?API=Verify' +
      '&XML=<AddressValidateRequest USERID="446SVDPO6362">' +
      '<Address ID="0"><Address1></Address1>' +
      '<Address2>' + address + '</Address2>' +
      '<City>' + city + '</City>' +
      '<State>' + state + '</State>' +
      '<Zip5>' + zip + '</Zip5>' +
      '<Zip4></Zip4>' +
      '</Address></AddressValidateRequest>';

    return this.http.get(url, { responseType: 'text' });
  }

  baseScrubbing(obj) {
    console.log('cash value start ' + obj.Cash);
    obj.Phone = this.formatPhone(obj.Phone);
    obj.Cash = this.formatNumber(obj.Cash);
    obj.Cards = this.formatNumber(obj.Cards);
    obj.Food = this.formatNumber(obj.Food);
    obj.DateMillis = Date.parse(obj.Date.trim());

    if (obj.FirstName !== undefined) {
      obj.FirstName = obj.FirstName.toUpperCase();
    }

    if (obj.LastName !== undefined) {
      obj.LastName = obj.LastName.toUpperCase();
    }

    if (obj.Address !== undefined) {
      obj.Address = obj.Address.toUpperCase();
    }

    if (obj.Apt !== undefined) {
      obj.Apt = obj.Apt.toUpperCase();
    }

    if (obj.City !== undefined) {
      obj.City = obj.City.toUpperCase();
    }

    if (obj.State !== undefined) {
      obj.State = obj.State.toUpperCase();
    }

    obj.CreatedBy = this.getUserMessage();
    obj.CreatedDate = Date.now();

    return obj;
  }

  baseValidation(obj) {
    const messages = [];

    if (obj.FirstName.length === 0) {
      messages.push('Missing First Name');
    }
    if (obj.LastName.length === 0) {
      messages.push('Missing Last Name');
    }
    if (!this.isValidPhone(obj.Phone)) {
      messages.push('Invalid Phone Number.  Please use 10 digit phone number like 3033784224.');
    }
    if (obj.Cash !== undefined && !this.isValidNumber(obj.Cash)) {
      messages.push('Cash/ Check values should be numeric values only example 134.56');
    }
    if (obj.Cards !== undefined && !this.isValidNumber(obj.Cards)) {
      messages.push('Card values should be numeric values only example 25');
    }
    if (obj.Food !== undefined && !this.isValidNumber(obj.Food)) {
      messages.push('Food values should be numeric values only example 50');
    }

    // Date formatting
    if (!this.isValidDate(obj.Date)) {
      messages.push('Missing or invalid date format: \"' + obj.Date + '\". Please use format with 4 digit year 5/5/2018.');
    }

    return messages;
  }

  getCallLogs(search): Observable<CallLog[]> {
    const url = this.baseUrl + '/call-logs?filter=' + search;

    const options = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': localStorage.getItem('access_token')
      })
    };

    return this.http.get<CallLog[]>(url, options );
  }

  getAbuseAlerts(search): Observable<any> {
    const url = this.baseUrl + '/search-abuse-alerts?filter=' + search;

    const options = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': localStorage.getItem('access_token')
      })
    };

    return this.http.get<CallLog[]>(url, options );
  }

  getAbuseEmail(messageId): Observable<any> {
    const url = this.baseUrl + '/abuse-email?message=' + messageId;

    const options = {
      headers: new HttpHeaders({
        'Content-Type':  'text/plain',
        'Token': localStorage.getItem('access_token')
      })
    };

    return this.http.get<any>(url, options );
  }

  getBankTransactions(search): Observable<BankForm[]> {
    const url = this.baseUrl + '/bank-transactions?filter=' + search;

    const options = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': localStorage.getItem('access_token')
      })
    };

    return this.http.get<BankForm[]>(url, options );
  }

  getMonthlyRollup(): Observable<MonthlyRollup[]> {
    const url = this.baseUrl + '/monthly-rollup';

    const options = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': localStorage.getItem('access_token')
      })
    };

    return this.http.get<MonthlyRollup[]>(url, options );
  }

  getUser(userID): Observable<User> {
    const url = this.baseUrl + '/get-user?id=' + userID;

    const options = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': localStorage.getItem('access_token')
      })
    };

    return this.http.get<User>(url, options);
  }

  saveClientForm(client): Observable<Client> {
    console.log('saveClientForm()');
    const url = this.baseUrl + '/save-client';

    const options = {
      headers: new HttpHeaders({
        'Token': localStorage.getItem('access_token')
      })
    };

    return this.http.post<Client>(url, client, options)
      .timeout(10000);
  }

  saveBankForm(bankForm): Observable<BankForm> {
    const url = this.baseUrl + '/save-bank-form';

    const options = {
      headers: new HttpHeaders({
        'Token': localStorage.getItem('access_token')
      })
    };

    return this.http.post<BankForm>(url, bankForm, options)
      .timeout(10000);
  }

  saveCallLog(callLog) {
    const url = this.baseUrl + '/save-calllog';

    const options = {
      headers: new HttpHeaders({
        'Token': localStorage.getItem('access_token')
      })
    };

    return this.http.post<CallLog>(url, callLog, options)
      .timeout(10000);
  }

  isValidDate(dateString) {
    // First check for the pattern
    if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) {
      return false;
    }

    // Parse the date parts to integers
    const parts = dateString.split('/');
    const day = parseInt(parts[1], 10);
    const month = parseInt(parts[0], 10);
    const year = parseInt(parts[2], 10);

    // Check the ranges of month and year
    if (year < 1000 || year > 3000 || month === 0 || month > 12) {
      return false;
    }

    const monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];

    // Adjust for leap years
    if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
      monthLength[1] = 29;
    }

    // Check the range of the day
    return day > 0 && day <= monthLength[month - 1];
  }

  formatPhone(phone) {
    phone = phone.replace('(', '');
    phone = phone.replace(')', '');
    phone = phone.replace(/-/g, '');
    phone = phone.replace(/ /g, '');

    return phone.trim();
  }

  isValidPhone(phone) {
    return phone.length === 10;
  }

  formatNumber(value) {
    if (typeof value === 'string' || value instanceof String) {
      value = value.replace('$', '');
      return value.trim();
    } else {
      return value;
    }
  }

  isValidNumber(value) {
    if (value !== undefined && value.length > 0) {
      return !isNaN(parseFloat(value)) && isFinite(value);
    } else {
      return true;
    }
  }

  test() {
    console.log('test()');
  }

  log(message) {
    const d = new Date();
    let dateStr = '' + (d.getMonth() + 1) + '/' + d.getDate() + '/' + d.getFullYear();
    dateStr += ' ' + d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds() + ' ';
    console.log(dateStr + message);
  }

}
