import { Component, OnInit } from '@angular/core';
import {Client} from '../../client';
import {ToolsService} from '../../tools.service';

declare const google: any;

@Component({
  selector: 'app-food-application',
  templateUrl: './food-application.component.html',
  styleUrls: ['./food-application.component.css']
})
export class FoodApplicationComponent implements OnInit {

  constructor(private toolsService: ToolsService) { }

  ngOnInit() {
    const mapProp = {
      center: new google.maps.LatLng(39.7634727, -104.9725622),
      zoom: 5,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    const map = new google.maps.Map(document.getElementById('googleMap'), mapProp);

    const cacheBust = (new Date()).getTime();
    const layer = new google.maps.KmlLayer('https://joelstein.github.io/svdp-denver-map/boundaries.kml?cacheBust=' + cacheBust, {
      map: map,
      preserveViewport: true
    });

    const markers = [];
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({'address': '14829 e maplewood dr, centennial, co, 80016'}, function(results, status) {
      console.log('results: ' + String(results));
      map.setCenter(results[0].geometry.location);

      const marker = new google.maps.Marker({
        map: map,
        position: results[0].geometry.location,
        title: results[0].formatted_address
      });
      markers.push(marker);

      map.set('zoom', 10);
    });

      // Add KML layer.
    // const cacheBust = (new Date()).getTime();
    // const layer = new google.maps.KmlLayer('https://joelstein.github.io/svdp-denver-map/boundaries.kml?cacheBust=' + cacheBust, {
    //  map: map,
    //  preserveViewport: true
    // });
  }

}
