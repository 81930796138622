import {Component, OnInit} from '@angular/core';
import {ToolsService} from './tools.service';
import {AuthService} from './auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';

const jwt = new JwtHelperService();

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [AuthService]
})
export class AppComponent implements OnInit {
  title = 'SVdP';
  isAuth = false;

  constructor(public auth: AuthService,
              private toolsService: ToolsService) { }

  ngOnInit() {
    this.auth.handleAuthentication();

    setTimeout(() => {
      this.checkAuthRedirect();
    }, 1000);
    // this.checkAuthRedirect();
  }

  checkAuthRedirect() {
    this.isAuth = this.auth.isAuthenticated();
    if (!this.isAuth) {
      this.auth.login();
    } else {
      const accessToken = localStorage.getItem('access_token');
      const idToken = localStorage.getItem('id_token');

      const decoded = jwt.decodeToken(idToken);
      const userID = decoded.sub;

      /*
      this.toolsService.getUser(userID)
        .subscribe(results => {
          this.toolsService.setUserName(results.name);
        });
        */
    }

  }
}
