import { Component, OnInit } from '@angular/core';
import {ToolsService} from '../../tools.service';
import {CallLog} from '../../callLog';
import {Client} from '../../client';
import { parseString } from 'xml2js';
import 'rxjs/add/operator/finally';

declare const google: any;

@Component({
  selector: 'app-call-log',
  templateUrl: './call-log.component.html',
  styleUrls: ['./call-log.component.css']
})
export class CallLogComponent implements OnInit {

  constructor(private toolsService: ToolsService) {

    // check if we are updating an existing record
    if (this.toolsService.savedCallLog) {
      this.callLog = this.toolsService.savedCallLog;
      this.toolsService.savedCallLog = undefined;
      this.callLog.HistoryCount = -1;
    } else {
      // default to a new application
      this.callLog = new CallLog();
    }

    this.getBoundaries();
  }

  // create empty object
  callLog: CallLog = new CallLog();
  messages = [];
  page1 = true;
  page2 = false;
  boundaries = [];

  ngOnInit() {
  }

  showPage(pageNum) {
    this.page1 = false;
    this.page2 = false;

    switch (pageNum) {
      case 1:
        this.page1 = true;
        break;
      case 2:
        this.page2 = true;
        break;
    }
  }

  saveCallLog() {
    console.log('save call log');
    this.callLog = this.toolsService.baseScrubbing(this.callLog);
    this.messages = this.toolsService.baseValidation(this.callLog);

    console.log('scrubAddress');
    this.toolsService.scrubAddress(this.callLog.Address, this.callLog.City, this.callLog.State, this.callLog.Zip)
      .finally(() => {
        console.log('finally block');
        // this code runs after running the address stardardization
        if (this.messages.length === 0) {
          // save call log to db
          console.log('do save');
          this.toolsService.saveCallLog(this.callLog)
            .subscribe((results) => {
              this.callLog._id = results._id;
            });

          this.searchHistory();

          // check if an address was enetered - if so draw google map with boundaries
          if (this.callLog.Address !== undefined && this.callLog.Address.length > 0) {
            this.drawMap();
          }
        }
      })
      .subscribe((results) => {
        parseString(results, (err, result) => {
          const addr = result.AddressValidateResponse.Address[0];

          if (this.callLog.Address !== undefined && this.callLog.Address.length > 0 && addr.Error) {
            // do nothing on address standardization error with USPS - too many issues with service (like lag on new addresses)
          } else {
            if (addr.Address2) {
              this.callLog.Address = String(addr.Address2);
            }
            if (addr.City) {
              this.callLog.City = String(addr.City);
            }
            if (addr.State) {
              this.callLog.State = String(addr.State);
            }
            if (addr.Zip5) {
              this.callLog.Zip = String(addr.Zip5);
            }
          }
        });
      }, (error) => {
        console.log('error on scrubAddress ' + error);
      });
  };

  searchHistory() {
    console.log('searchHistory');
    this.toolsService.getClients(this.callLog.Phone)
      .subscribe(results => {
        this.callLog.HistoryCount = results.length;
      });
  };

  drawMap() {
    console.log('draw map');
    // default map object/ location
    const mapProp = {
      center: new google.maps.LatLng(39.7634727, -104.9725622),
      zoom: 5,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    const map = new google.maps.Map(document.getElementById('googleMap'), mapProp);

    const cacheBust = (new Date()).getTime();
    const layer = new google.maps.KmlLayer('https://joelstein.github.io/svdp-denver-map/boundaries.kml?cacheBust=' + cacheBust, {
      map: map,
      preserveViewport: true
    });

    const markers = [];
    const geocoder = new google.maps.Geocoder();
    const addressStr = this.callLog.Address + ',' + this.callLog.City + ',' + this.callLog.State + ',' + this.callLog.Zip;
    console.log('do geocode');
    geocoder.geocode({'address': addressStr}, (results, status) => {
      console.log('got geocode result');
      map.setCenter(results[0].geometry.location);

      const marker = new google.maps.Marker({
        map: map,
        position: results[0].geometry.location,
        title: results[0].formatted_address
      });
      markers.push(marker);

      map.set('zoom', 10);

      for (const boundary of this.boundaries) {
        const poly = new google.maps.Polygon({paths: boundary.coords});
        const resp = google.maps.geometry.poly.containsLocation(results[0].geometry.location, poly);
        if(resp === true ) {
          this.callLog.ConferenceName = boundary.name;
          this.callLog.ConferencePhone = boundary.phone;
        }
      }

      // for (google.maps.KmlPlacemark placemark: layer.getPlacemarks()) {
      // for (const conference of this.getAllConferences()) {
        // point in polygon
        // const curPosition = new google.maps.LatLng(25.774, -80.19);
        // const coords = [
        //   {lat: 25.774, lng: -80.19},
        //   {lat: 18.466, lng: -66.118},
        //   {lat: 32.321, lng: -64.757}
        // ];
        // const poly = new google.maps.Polygon({paths: conference.coords});
        // const resp = google.maps.geometry.poly.containsLocation(results[0].geometry.location, poly);
      // }

    });
  };

  newCallLog() {
    this.callLog = new CallLog();
    this.showPage(1);
  }

  getBoundaries() {
    console.log('getBoundaries()');

    this.toolsService.getBoundaries()
      .subscribe(results => {
        console.log('parse boundary results');
        parseString(results, (err, result) => {
          const placemarks = result.kml.Document[0].Folder[0].Placemark;
          for (const placemark of placemarks) {
            if (placemark.Polygon) {
              for (const polygon of placemark.Polygon) {
                const obj = {'name': placemark.name, 'phone': placemark.phone, 'coords': this.getCoords(polygon)};
                this.boundaries.push(obj);
              }
            }
            if (placemark.MultiGeometry) {
              for (const geometry of placemark.MultiGeometry) {
                for (const polygon of geometry.Polygon) {
                  const obj = {'name': placemark.name, 'phone': placemark.phone, 'coords': this.getCoords(polygon)};
                  this.boundaries.push(obj);
                }
              }
            }
          }
          console.log('finished get boundaries');
        });
      });
  }

  getCoords(polyIn) {
    const coords = [];
    const str = polyIn.outerBoundaryIs[0].LinearRing[0].coordinates[0];

    for (const row of str.split('\n')) {
      const arr = row.split(',');
      const coord = {lat: Number(arr[1]), lng: Number(arr[0])};
      coords.push(coord);
    }
    return coords;
  }

}
