import { Component, OnInit } from '@angular/core';
import {ToolsService} from '../../tools.service';
import {CallLog} from '../../callLog';
import {BankForm} from '../../bankForm';

@Component({
  selector: 'app-bank-transactions-report',
  templateUrl: './bank-transactions-report.component.html',
  styleUrls: ['./bank-transactions-report.component.css']
})
export class BankTransactionsReportComponent implements OnInit {

  transactions: BankForm[] = [];
  processing = false;

  constructor(private toolsService: ToolsService) {
    this.getTransactions();
  }

  ngOnInit() {
  }

  getTransactions() {
    this.toolsService.getBankTransactions('')
      .subscribe(results => {
        this.processing = false;

        for(const obj of results) {
          this.transactions.push(obj);
        }
      });
  }

}
