import { Component, OnInit } from '@angular/core';
import { ToolsService } from '../../tools.service';
import { Client } from '../../client';
import { Router } from '@angular/router';

@Component({
  selector: 'app-report',
  templateUrl: './client-application.component.html',
  styleUrls: ['./client-application.component.css']
})
export class ClientApplicationComponent implements OnInit {

  // create empty client
  client: Client;

  page1 = true;
  page2 = false;
  page3 = false;
  page4 = false;
  page5 = false;
  page6 = false;
  page7 = false;
  page8 = false;

  messages = [];
  ethnicityList = [
    {'id': 0, 'name': 'Select'},
    {'id': 1, 'name': 'Caucasian'},
    {'id': 2, 'name': 'Asian'},
    {'id': 3, 'name': 'Hispanic/ Latino'},
    {'id': 4, 'name': 'African American'},
    {'id': 5, 'name': 'Pacific Islander'},
    {'id': 6, 'name': 'Native American Indian'},
    {'id': 7, 'name': 'Unknown'}];

  constructor(private toolsService: ToolsService, private router: Router) {

    // check if we are promototing a call log to a new application
    if (router.url === '/promoteCall') {
      console.log('promote call');
      const callLog = this.toolsService.savedCallLog;
      this.client = new Client();
      this.client.FirstName = callLog.FirstName;
      this.client.LastName = callLog.LastName;
      this.client.Address = callLog.Address;
      this.client.Apt = callLog.Apt;
      this.client.City = callLog.City;
      this.client.State = callLog.State;
      this.client.Zip = callLog.Zip;
      this.client.Phone = callLog.Phone;
      this.client.Date = callLog.Date;
    } else if (this.toolsService.savedClient) {
      // check if we are updating an existing application
      this.client = this.toolsService.savedClient;
      this.toolsService.savedClient = undefined;
    } else {
      // default to a new application
      this.client = new Client();
    }
  }

  ngOnInit() {
  }

  showPage(pageNum) {
    console.log('ethnicity ' + this.client.Ethnicity);
    this.page1 = false;
    this.page2 = false;
    this.page3 = false;
    this.page4 = false;
    this.page5 = false;
    this.page6 = false;
    this.page7 = false;
    this.page8 = false;

    switch (pageNum) {
      case 1:
        this.page1 = true;
        break;
      case 2:
        this.page2 = true;
        break;
      case 3:
        this.page3 = true;
        break;
      case 4:
        this.page4 = true;
        break;
      case 5:
        this.page5 = true;
        break;
      case 6:
        this.page6 = true;
        break;
      case 7:
        this.page7 = true;
        break;
      case 8:
        this.page8 = true;
        break;
    }
  }

  saveReport() {
    this.client = this.toolsService.baseScrubbing(this.client);
    this.messages = this.toolsService.baseValidation(this.client);

    if (this.messages.length === 0 ) {
      this.toolsService.saveClientForm(this.client)
        .subscribe((results) => {
          this.client._id = results._id;
        });
    }

  }

  newApplication() {
    this.client = new Client();
    this.showPage(1);
  }

}
