import { Component, OnInit } from '@angular/core';
import {ToolsService} from '../../tools.service';
import {CallLog} from '../../callLog';
import {MonthlyRollup} from '../../monthlyRollup';

@Component({
  selector: 'app-monthly-rollup',
  templateUrl: './monthly-rollup.component.html',
  styleUrls: ['./monthly-rollup.component.css']
})
export class MonthlyRollupComponent implements OnInit {

  data: MonthlyRollup[] = [];
  processing = false;

  constructor(private toolsService: ToolsService) {
    this.getData();
  }

  ngOnInit() {
  }

  getData() {
    this.toolsService.getMonthlyRollup()
      .subscribe(results => {
        this.processing = false;
        this.data = results;
      });
  }

}
